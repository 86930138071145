import React from "react"
import { graphql } from "gatsby"
import { DefaultLayout } from "~components/layout/Default"
import { SEO } from "~components/Seo"
import { GridColumn, GridRow } from "~components/atoms/Grid"
import { Headline } from "~components/atoms/Headline"
import { RteText } from "~components/atoms/RteText"
import { ContactBox } from "~components/elements/ContactBox"

import * as styles from "~styles/templates/news.module.scss"
import { Box } from "~components/atoms/Box"
import { translate } from "~utility/Sink"
import { LocalizedDate } from "~components/atoms/LocalizedDate"
import { trackFileDownload } from "../utility/tracking"

const GeneralMeetingTemplate = ({ data }) => {
    const meeting = data.meeting.data

    const { lang, type, url } = data.meeting || {}
    const alternateLanguages = data.meeting.alternate_languages || []
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const contact = data.contact.edges.find(item => item.node.lang === lang)
        .node.data

    return (
        <DefaultLayout
            variant="dark"
            activeDocMeta={activeDoc}
            title={meeting.name.text}
        >
            <SEO
                title={meeting.name.text}
                description={meeting.teaser_text.text}
                lang={lang}
            />

            <div className={styles.root}>
                <GridRow width="content">
                    <GridColumn largeSpacing s={12} m={8} l={8}>
                        <time>
                            <LocalizedDate date={new Date(meeting.date)} />
                        </time>
                        <Headline variant="h2">{meeting.name.text}</Headline>
                        {meeting.image?.url && (
                            <img
                                className={styles.image}
                                src={meeting.image.url}
                            />
                        )}
                        <RteText
                            content={meeting.text.html}
                            gutterBottom="large"
                        />
                    </GridColumn>
                    <GridColumn largeSpacing s={12} m={6} l={4}>
                        <ContactBox
                            language={lang}
                            item={{
                                headline: contact.name.text,
                                subline: contact.title.text,
                                address: contact.address.html,
                                phone: contact.telephone.text,
                                email: contact.email.text,
                            }}
                        />
                        {meeting.downloads &&
                            meeting.downloads
                                .filter(item => item.download.document?.data)
                                .map((download, i) => (
                                    <Box
                                        key={i}
                                        to={
                                            download.download.document.data.file
                                                .url
                                        }
                                        buttonLabel={translate(
                                            "newsroom.download_as_pdf",
                                            lang
                                        )}
                                        onClick={() => {
                                            trackFileDownload(
                                                download.download.document.data
                                                    .file.url,
                                                download.download.document.data
                                                    .file.size
                                            )
                                        }}
                                        padding
                                        externalLink
                                        gutterBottom
                                    >
                                        <Headline variant="h3">
                                            {
                                                download.download.document.data
                                                    .name.text
                                            }
                                        </Headline>
                                    </Box>
                                ))}
                    </GridColumn>
                </GridRow>
            </div>
        </DefaultLayout>
    )
}

export default GeneralMeetingTemplate

export const query = graphql`
    query GeneralMeetingQuery($uid: String!, $lang: String!) {
        contact: allPrismicContact(
            filter: { data: { role: { eq: "Investor Relations" } } }
        ) {
            edges {
                node {
                    lang
                    data {
                        address {
                            html
                        }
                        email {
                            text
                        }
                        name {
                            text
                        }
                        role
                        telephone {
                            text
                        }
                        title {
                            text
                        }
                    }
                }
            }
        }
        meeting: prismicGeneralMeeting(uid: { eq: $uid }, lang: { eq: $lang }) {
            lang
            alternate_languages {
                uid
                type
                lang
                url
            }
            data {
                date
                teaser_text {
                    text
                }
                name {
                    text
                }
                image {
                    url
                }
                text {
                    html
                }
                downloads {
                    download {
                        document {
                            ... on PrismicDownload {
                                id
                                data {
                                    file {
                                        url
                                        size
                                    }
                                    name {
                                        text
                                    }
                                    teaser {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
            uid
            lang
        }
    }
`
